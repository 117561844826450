import React, { useContext, useEffect, useState } from 'react';
import Layout from '../../../components/287634/layout/layout';
import { DynamicUserForm } from '../../../components/287634/form/dynamic-user';
import { DynamicUserStatus } from '../../../components/287634/form/dynamic-user/status';
import appService from '../../../services/endpoints';
import { navigateToCustomPage, navigateToError, navigateToFailed, navigateToSuccess } from '../../../utils/navigate';
import { Config, Command, CustomerStatus, Fields } from '../../../components/287634/form/dynamic-user/types';
import { format, IncomingDTO } from '../../../components/287634/form/dynamic-user/utils/format';
import { parse } from '../../../components/287634/form/dynamic-user/utils/parse';
import { useRecaptcha } from '../../../hooks/recaptcha.hook';
import { Loading } from '../../../components/287634/loading';
import pages from '../../../constants/pages.json';
import communicationService from '../../../services/communication';
import { CreditcardContext } from '../../../context/creditcard.context';
import { StepPin } from '../../../components/287634/form/dynamic-user/sections/step-pin';
import { StepData } from '../../../components/287634/form/dynamic-user/sections/step-data';
import { StepResetPin } from '../../../components/287634/form/dynamic-user/sections/step-reset-pin';
import { StepNormal } from '../../../components/287634/form/dynamic-user/sections/step-normal';
import { isNull } from 'lodash';

const DynamicUser = ({ getToken, location }: PageProps) => {
  const [config, setConfig] = useState<Config | null>(null);
  const [command, setCommand] = useState<Command | null>(null);
  const [customerStatus, setCustomerStatus] = useState<CustomerStatus | null>(null);
  const [status, setStatus] = useState<'success' | 'failed' | 'expired'>();
  const [statusIntent, setStatusIntent] = useState('');
  const [communicationToken, setCommunicationToken] = useState();
  const [isAliasError, setIsAliasError] = useState(false);
  const [isPinError, setIsPinError] = useState<'repeated' | 'userBirthDate'>();
  const { getRecaptchaToken } = useRecaptcha(!!config?.fields.pin);
  const [isResponseFinished, setIsResponseFinished] = useState<boolean>(true);
  const token = getToken();
  const { setLoading } = useContext(CreditcardContext);
  const handleSubmit = async (fields: Fields<string>) => {
    setIsAliasError(false);
    setIsResponseFinished(false);
    setIsPinError(undefined);
    try {
      const recaptchaToken = config?.fields.pin ? await getRecaptchaToken() : null;

      const isUpdatePin = config?.fields.pin ? true : false;

      const { action, url, intent, feedback, communication_token } = await appService.patchDynamicUserData(
              token,
              parse(fields, recaptchaToken),
              isUpdatePin
      );

      if (action === 'redirect') {
        if (command === 'dynamic_onboarding' && (customerStatus === 'OB_TYC_GDPR' || customerStatus === 'OB_GUEST')) {
          navigateToCustomPage(`/success/onboarding-pin${location.search}`)?.();
        } else {
          navigateToCustomPage(`/${url}`)?.();
        }
      } else if (action === 'paid') {
        const state = {
          from: pages.SUCCESS_PAGE.PAID,
          response: feedback,
        };
        navigateToSuccess({ state, callback: () => setLoading?.(false) })?.();
      } else if (action === 'finished') {
        if (command === 'dynamic_onboarding' && (customerStatus === 'OB_LIVENESS' || customerStatus === 'OB_GUEST')) {
          navigateToCustomPage(`/success/onboarding-data${location.search}`)?.();
        } else if (command === 'dynamic_reset_pin') {
          navigateToCustomPage(`/email-code/success`)?.();
        } else {
          console.log('cayo');
          const state = {
            from: pages.SUCCESS_PAGE.UPDATE_CUSTOMER,
            response: feedback,
          };
          navigateToSuccess({ state })?.();
        }
      } else if (action === 'custom_error') {
          navigateToError()?.();
      } else {
        setStatus('success');
        setStatusIntent(intent);
        // @todo: instead of piling up more logic here, we could probably abstract out
        // this communication token logic into a separate provider, or something
        setCommunicationToken(communication_token);
      }
    } catch (error: any) {
      if (error.response?.data?.details[0]?.error_code === 'FGS1903' ||
        error.response?.data?.details[0]?.error_code === 'FGS1100') {
        const state = {
          from: pages.FAILED_PAGE.DENIED,
        };
        navigateToFailed({ state })?.();
      } else if (error.response?.data?.details[0]?.error_code === 'FGS1904') {
        setIsPinError('repeated');
        setIsResponseFinished(true);
      } else if (error.response?.data?.details[0]?.error_code === 'FGS1905') {
        setIsPinError('userBirthDate');
      } else {
        navigateToError()?.();
      }
    }
  };

  useEffect(() => {
    if (!token) {
      navigateToError()?.();
    } else {
      appService
        .getDynamicUserData<IncomingDTO>(token)
        .then(format)
        .then(result => {
          setConfig(result.config);
          setCommand(result.command);
          setCustomerStatus(result.customerStatus);
        })
        .catch(() => navigateToError()?.());
    }
  }, []);

  if (status) {
    return <DynamicUserStatus statusType={status} intent={statusIntent} communicationToken={communicationToken} />;
  }

  if (!config) {
    return <Loading />;
  }

  return (
    <Layout>      
      {command === 'dynamic_onboarding' && config.fields.birthDate == null && <StepPin />}
      {command === 'dynamic_onboarding' && config.fields.pin == null && <StepData />}
      {command === 'dynamic_reset_pin' && <StepResetPin />}
      {command !== 'dynamic_onboarding' && command !== 'dynamic_reset_pin' && <StepNormal />}

      <DynamicUserForm
        command={command}
        customerStatus={customerStatus}
        config={config}
        submit={handleSubmit}
        token={token}
        isAliasError={isAliasError}
        isResponseFinished={isResponseFinished}
        isPinError={isPinError}
      />
    </Layout>
  );
};

export default DynamicUser;
