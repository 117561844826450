import React, { useContext } from 'react';
import * as Yup from 'yup';
import { AddFieldProps } from '../types';
import { graphql, useStaticQuery } from 'gatsby';
import { LangContext } from '../../../../../context/lang.context';
import { getMask } from '../../../../forms/dynamic-user/utils/format';
import { CompanyContext } from '../../../../../context/company.context';
import { getText } from '../../../../../utils/functions';
import { createMask } from '../../../../../utils/masks';
import { Field } from '../../field/field';
import { Input } from '../../input/input';

export const DocumentField = ({ fields, schemas, formik }: AddFieldProps) => {
  const { t, lang } = useContext(LangContext);
  const { clientId, governmentValidationType } = useContext(CompanyContext);

  const data = useStaticQuery(graphql`
    query {
      allGovernmentIdentificationService {
        nodes {
          clientId
          locale
          DOCUMENT_TYPE {
            id
            regex
            value
          }
        }
      }
      allI18NJson {
        nodes {
          locale
          clientId
          DYNAMIC_USER_FORM {
            FIELDS {
              DOCUMENT {
                NUMBER {
                  LABEL_1
                  LABEL_2
                  REQUIRED
                  SPECIAL_CHARS
                  MIN_LENGTH
                  INVALID
                }
                HEADING
                TITLE
                TYPE {
                  LABEL
                  REQUIRED
                }
              }
            }
          }
        }
      }
    }
  `);

  const getRegex = (): string =>
    getText(data.allGovernmentIdentificationService.nodes, lang, clientId)
      .DOCUMENT_TYPE.find((e: any) => e?.id === formik?.values.documentType)
      ?.regex?.slice(1, -1);

  const documentTypes = getText(data.allGovernmentIdentificationService.nodes, lang, clientId).DOCUMENT_TYPE;
  const valueType = formik?.values.documentType;
  const documentNumber = fields?.documentNumber!;
  const documentType = fields?.documentType!;

  if (documentType?.required || documentType?.defaultValue) {
    schemas.documentType = Yup.string().required(t(data).DYNAMIC_USER_FORM.FIELDS.DOCUMENT.TYPE.REQUIRED);
  }
  if (governmentValidationType === 'Multiple') {
    schemas.documentNumber = Yup.string().validDocument(t(data).DYNAMIC_USER_FORM.FIELDS.DOCUMENT.NUMBER.INVALID);
  }

  const regex = getRegex();
  let mask: RegExp[];

  if (governmentValidationType === 'Multiple') {
    mask = createMask('NUMBER', 8);
    if (formik?.values.documentType === 'PASAPORTE') {
      mask = createMask('PASSPORT', 9);
    }
  } else {
    mask = getMask(valueType, regex);
  }

  return (
    <div>
      <Field
        key="documentType"
        label={t(data).DYNAMIC_USER_FORM.FIELDS.DOCUMENT.TYPE.LABEL}
        required={documentType?.required}
        warning={formik?.errors.documentType}
        readOnly={documentType?.readOnly}
      >
        <select
          defaultValue={formik?.values.documentType}
          onChange={(e: any) => {
            formik?.setFieldValue('documentType', e.target.value);
            formik?.setFieldValue('documentNumber', '');
          }}
          className="w-full appearance-none bg-transparent font-body text-[16px] text-primary focus:outline-none focus-visible:outline-none disabled:opacity-100"
          disabled={documentType?.readOnly}
        >
          <option value="" disabled>
            Seleccionar tipo de documento
          </option>
          {documentTypes.map(({ value, id }: any) => (
            <option key={id} value={id}>
              {value}
            </option>
          ))}
        </select>
      </Field>

      <Field
        key="documentNumber"
        label={t(data).DYNAMIC_USER_FORM.FIELDS.DOCUMENT.NUMBER.LABEL_1}
        required={documentNumber?.required}
        warning={formik?.errors.documentNumber}
        readOnly={documentNumber?.readOnly}
      >
        <Input
          value={formik?.values.documentNumber}
          onChange={formik?.handleChange('documentNumber')}
          className="text-fill-primary w-full bg-transparent font-body text-base leading-tight text-primary focus:outline-none focus-visible:outline-none"
          guide={false}
          disabled={documentNumber?.readOnly}
        />
      </Field>
    </div>
  );
};
